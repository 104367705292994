






import { Component, Prop, Vue } from 'vue-property-decorator'

import SubNavigation from '@/views/dashboard/projects/partials/SubNavigation.vue'

import { ProjectResource } from '@/models/projects/ProjectResource'
import { CorlyticsService } from '@/services/corlytics'

@Component({
  components: {
    SubNavigation
  }
})
export default class ProjectsDetailIndex extends Vue {
  @Prop()
  private readonly project!: ProjectResource
  private corlyticsService: CorlyticsService = new CorlyticsService()
  private metaTags: any = null

  private mounted() {
    this.getMetadata()
  }

  private async getMetadata() {
    try {
      const meta = await this.corlyticsService.getMetadata(this.project.id)
      this.metaTags = meta || {}    
    }
    catch(e) {}
  }

}
